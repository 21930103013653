import React, { useState, MouseEvent } from "react";
// @ts-ignore
import { BarChart, Breadcrumbs, Carousel, Header } from "ui-library";
// @ts-ignore
import { PieChart, ToggleButton } from "ui-library";
// @ts-ignore
import { Banner, Footer, GlobalStyle, HeadingM, WhitneyFont } from "ui-library";
// @ts-ignore
import { ProductCardSection } from "ui-library";
// @ts-ignore
import { Accordion, AccordionItem } from "ui-library";
// @ts-ignore
import { PageCardSection, Profile } from "ui-library";
// @ts-ignore
import { Button, Select, HeadingS, Typography } from "ui-library";
// @ts-ignore
import { StockChart, KeyFigureSection, Container } from "ui-library";
// @ts-ignore
import { SignColorNumber, ProductCardItem, ProductCardList } from "ui-library";

import "./App.css";

import {
  argsProfile,
  customOptionsPage1,
  customOptionsPage2,
  customOptionsPage3,
} from "./mockData/allocationInsights.mock";
import { stockChartOptions } from "./mockData/graph.mock";
import {
  argsFooter,
  bannerArgs,
  breadcrumbsArgs,
} from "./mockData/layout.mock";
import {
  analysisCards,
  toggleButtonNewsWithoutIcons,
} from "./mockData/news.mock";
import { argsProductCardSection } from "./mockData/accounts.mock";

function App() {
  const [options, setOptions] = useState(stockChartOptions);

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 5000);

  const clickToggleButtonNews = (event: any, newSelected: number) => {
    setActiveNewsTab(newSelected);
    const newItems = toggleButtonNews.items.map((x) => {
      if (x.id === newSelected) {
        return { ...x, selected: true };
      }
      if (x.selected) {
        return { ...x, selected: false };
      }
      return x;
    });
    const newState = { ...toggleButtonNews, items: newItems };
    setToggleButtonNews(newState);
  };

  const toggleButtonNewsMock = {
    className: "togleBtnNews",
    items: [
      {
        id: 1,
        displayName: "Analysis",
        selected: true,
        icon: "analysis",
        onClick: (e: any) => {
          clickToggleButtonNews(e, 1);
        },
      },
      {
        id: 2,
        displayName: "News",
        selected: false,
        icon: "article",
        onClick: (e: any) => {
          clickToggleButtonNews(e, 2);
        },
      },
    ],
    variant: "default" as const,
    theme: "white",
  };

  const [activeNewsTab, setActiveNewsTab] = useState(
    toggleButtonNewsMock.items.find((x) => x.selected)?.id
  );

  const [toggleButtonNews, setToggleButtonNews] =
    React.useState(toggleButtonNewsMock);

  const updateSeriesRadom = () => {
    const randomItem =
      stockChartOptions.series[
        Math.floor(Math.random() * stockChartOptions.series.length)
      ];
    const newSeries = stockChartOptions.series.filter(
      (x) => x.name !== randomItem.name
    );
    const newOptions = { ...stockChartOptions, series: newSeries };
    setOptions(newOptions);
  };
  const [values, setValues] = useState<string[]>(["1234"]);

  const updateSeries = (value: string[]) => {
    setValues(value);
    const newSeries = stockChartOptions.series.filter((x) =>
      value.includes(x.name)
    );
    const newOptions = { ...stockChartOptions, series: newSeries };
    setOptions(newOptions);
  };

  const graphToggleButton = {
    className: "graphToggleButton",
    items: [
      {
        id: 1,
        displayName: "3 mos",
        selected: false,
        onClick: updateSeriesRadom,
      },
      {
        id: 2,
        displayName: "6 mos",
        selected: false,
        onClick: updateSeriesRadom,
      },
      {
        id: 3,
        displayName: "YTD",
        selected: true,
        onClick: updateSeriesRadom,
      },
      {
        id: 4,
        displayName: "1 yr",
        selected: false,
        onClick: updateSeriesRadom,
      },
      {
        id: 5,
        displayName: "3 yr",
        selected: false,
        onClick: updateSeriesRadom,
      },
      {
        id: 6,
        displayName: "5 yr",
        selected: false,
        onClick: updateSeriesRadom,
      },
      {
        id: 7,
        displayName: "Since start",
        selected: false,
        onClick: updateSeriesRadom,
      },
    ],
    variant: "default" as const,
    theme: "white",
  };

  const selectGraphArgs = {
    elementName: "select",
    multiple: true,
    defaultValue: ["1234"],
    onChange: (event: any) => {
      updateSeries(event.target.value);
    },
  };

  return (
    <>
      <GlobalStyle />
      <WhitneyFont />
      <div className="App">
        <Banner {...bannerArgs} />
        <Breadcrumbs {...breadcrumbsArgs} />
        <Container theme={"lightgray"}>
          <div className="row">
            <div className="leftcol">
              <Container theme={"white"}>
                <div className="row" id="graphBlockFilters">
                  <div className="graphleftcol">
                    {!loading && <ToggleButton {...graphToggleButton} />}
                    {/* <ToggleButton {...graphToggleButton}/> */}
                  </div>
                  <div className="graphrightCol">
                    <Select
                      loading={loading}
                      value={values}
                      {...selectGraphArgs}
                    >
                      <option value="1234">Private</option>
                      <option value="2222">Demo Persson</option>
                      <option value="1111">Demo Persson1</option>
                    </Select>
                  </div>
                </div>
                <Accordion theme="white" slim styleVariant="standard">
                  <div>
                    <KeyFigureSection
                      anchorName="keyfigure-anchor-header"
                      display="flex"
                      keyFigures={[
                        {
                          loading: loading,
                          keyFigure: 123456789,
                          text: "market value (usd)",
                          animated: true,
                        },
                        {
                          loading: loading,
                          keyFigure: 123456,
                          text: "development (usd)",
                          percentage: 3.8,
                          animated: true,
                        },
                      ]}
                      identifier="751cc379-eb67-4132-9bb0-01ea55bdb240"
                    />
                  </div>
                  <div>
                    <KeyFigureSection
                      anchorName="keyfigure-anchor"
                      keyFigures={[
                        {
                          keyFigure: 123456789,
                          text: "market value (usd)",
                          animated: false,
                        },
                        {
                          keyFigure: 123456,
                          text: "development (usd)",
                          percentage: 3.8,
                          animated: false,
                        },
                        {
                          keyFigure: 123000,
                          text: "dividends (total)",
                          percentage: 3.8,
                          animated: false,
                        },
                        {
                          keyFigure: 123456789,
                          text: "market value (usd)",
                          animated: false,
                        },
                      ]}
                      identifier="751cc379-eb67-4132-9bb0-01ea55bdb240"
                    />
                  </div>
                </Accordion>
                <StockChart loading={loading} customOptions={options} />
              </Container>

              <ProductCardSection loading={loading} {...argsProductCardSection}>
                <ProductCardList
                  loading={false}
                  title="PRIVATE CAPITAL"
                  aggregateFigures="30 232 342 KR"
                  aggregateDescription={
                    <>
                      <SignColorNumber
                        number={-0.42}
                        animated={false}
                        suffix="%"
                        biggerSize="S"
                      />
                      {" YTD"}
                    </>
                  }
                >
                  <ProductCardItem
                    icon="money"
                    title="S&P ISK - 223939"
                    description="Available funds: 37 000 kr"
                    url="https://www.google.com"
                    topRightDescription="30 232 342 KR"
                    onClick={(event: MouseEvent) => {
                      event.preventDefault();
                      alert("clicked");
                    }}
                    rightDescription={
                      <>
                        <SignColorNumber
                          number={0.42}
                          animated={false}
                          suffix="%"
                          biggerSize="S"
                        />
                        {" YTD"}
                      </>
                    }
                  />
                  <ProductCardItem
                    icon="money"
                    title="S&P ISK - 223939"
                    description="Available funds: 57 000 kr"
                    url="https://www.google.com"
                    target="_blank"
                    topRightDescription="75 232 342 KR"
                    rightDescription={
                      <>
                        <SignColorNumber
                          number={0.42}
                          animated={false}
                          suffix="%"
                          biggerSize="S"
                        />
                        {" YTD"}
                      </>
                    }
                  />
                </ProductCardList>
                <ProductCardList
                  title="STAFFAN PEARSON"
                  aggregateFigures="30 232 342 KR"
                  aggregateDescription={
                    <>
                      <SignColorNumber
                        number={-0.42}
                        animated={false}
                        suffix="%"
                        biggerSize="S"
                      />
                      {" YTD"}
                    </>
                  }
                >
                  <ProductCardItem
                    loading={true}
                    icon="pension"
                    title="S&P ISK - 223939"
                    description="Available funds: 37 000 kr"
                    url="https://www.google.com"
                    target="_blank"
                    topRightDescription="30 232 342 KR"
                    rightDescription={
                      <>
                        <SignColorNumber
                          number={0.42}
                          animated={false}
                          suffix="%"
                          biggerSize="S"
                        />
                        {" YTD"}
                      </>
                    }
                  />
                </ProductCardList>
                <ProductCardList title="PROPERTY">
                  <ProductCardItem
                    icon="pension"
                    title="HOME INSURANCE"
                    description="Odengatan 3 LGH 1103"
                    topRightDescription="3492349-9394"
                    rightDescription={
                      <>
                        <span style={{ color: "var(--color-traffic-green)" }}>
                          Active
                        </span>
                        {" 12-02-2023 - 12-02-2024"}
                      </>
                    }
                    open={true}
                  >
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Pharetra convallis posuere morbi leo urna
                      molestie.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Pharetra convallis posuere morbi leo urna
                      molestie.
                    </p>
                  </ProductCardItem>
                  <ProductCardItem
                    icon="money"
                    title="VILLA INSURANCE"
                    description="Odengatan 3 LGH 1103"
                    topRightDescription="3492349-9394"
                    rightDescription={
                      <>
                        <span style={{ color: "var(--color-traffic-green)" }}>
                          Active
                        </span>
                        {" 12-02-2023 - 12-02-2024"}
                      </>
                    }
                    open={true}
                  >
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Pharetra convallis posuere morbi leo urna
                      molestie.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Pharetra convallis posuere morbi leo urna
                      molestie.
                    </p>
                  </ProductCardItem>
                </ProductCardList>
              </ProductCardSection>
            </div>
            <div className="rightCol">
              <Carousel theme="blue" contentTheme="cyan" height={550}>
                <div>
                  <Header
                    loading={loading}
                    icon="pension"
                    text="allocation"
                    title="Asset class"
                  />
                  <PieChart loading={loading} {...customOptionsPage1} />
                </div>
                <div>
                  <Header
                    loading={loading}
                    icon="pension"
                    text="ESG score"
                    title="Sustainability"
                  />
                  <PieChart loading={loading} {...customOptionsPage2} />
                </div>
                <div>
                  <Header
                    loading={loading}
                    icon="pension"
                    text="regional distribution"
                    title="Top 10"
                  />
                  <BarChart loading={loading} {...customOptionsPage3} />
                </div>
              </Carousel>

              <Profile theme="blue" loading={loading} {...argsProfile} />
            </div>
          </div>
        </Container>
        <Container theme={"white"}>
          <HeadingM className="newsTitle">News & analysis</HeadingM>
          <ToggleButton {...toggleButtonNews} />
          {activeNewsTab === 1 && (
            <Container>
              <PageCardSection {...analysisCards} />
              <Button
                variant="primary"
                size="medium"
                isLink={true}
                actionTheme="blue"
                url="/"
                linkText="Read more"
              />
            </Container>
          )}
          {activeNewsTab === 2 && (
            <Container id="newsTab">
              <ToggleButton {...toggleButtonNewsWithoutIcons} />
              <Accordion
                theme="white"
                contentTheme="black"
                expandedButtonLeft
                styleVariant="outlined"
              >
                <AccordionItem>
                  <HeadingS>Välja in hållbara investeringar. </HeadingS>
                  <Typography variant="body-m">
                    Vi rekommenderar produkter med ett grönt hållbarhetsbetyg
                    till dig som vill göra skillnad. Produkterna har mycket goda
                    förutsättningar att bidra till en mer hållbar värld.
                    Förvaltarna arbetar aktivt med att välja in hållbara bolag
                    och att påverka bolag i en hållbar riktning.
                  </Typography>
                  <Button
                    style={{
                      marginTop: "1.3125rem",
                    }}
                    variant="outline"
                    size="medium"
                    isLink
                    actionTheme="blue"
                    url="/"
                    linkText="Read More"
                  />
                </AccordionItem>
                <AccordionItem>
                  <HeadingS>Välja in hållbara investeringar. </HeadingS>
                  <Typography variant="body-m">
                    Vi rekommenderar produkter med ett grönt hållbarhetsbetyg
                    till dig som vill göra skillnad. Produkterna har mycket goda
                    förutsättningar att bidra till en mer hållbar värld.
                    Förvaltarna arbetar aktivt med att välja in hållbara bolag
                    och att påverka bolag i en hållbar riktning.
                  </Typography>
                </AccordionItem>
                <AccordionItem>
                  <HeadingS>Välja in hållbara investeringar. </HeadingS>
                  <Typography variant="body-m">
                    Vi rekommenderar produkter med ett grönt hållbarhetsbetyg
                    till dig som vill göra skillnad. Produkterna har mycket goda
                    förutsättningar att bidra till en mer hållbar värld.
                    Förvaltarna arbetar aktivt med att välja in hållbara bolag
                    och att påverka bolag i en hållbar riktning.
                  </Typography>
                </AccordionItem>
                <AccordionItem>
                  <HeadingS>Välja in hållbara investeringar. </HeadingS>
                  <Typography variant="body-m">
                    Vi rekommenderar produkter med ett grönt hållbarhetsbetyg
                    till dig som vill göra skillnad. Produkterna har mycket goda
                    förutsättningar att bidra till en mer hållbar värld.
                    Förvaltarna arbetar aktivt med att välja in hållbara bolag
                    och att påverka bolag i en hållbar riktning.
                  </Typography>
                </AccordionItem>
                <AccordionItem>
                  <HeadingS>Välja in hållbara investeringar. </HeadingS>
                  <Typography variant="body-m">
                    Vi rekommenderar produkter med ett grönt hållbarhetsbetyg
                    till dig som vill göra skillnad. Produkterna har mycket goda
                    förutsättningar att bidra till en mer hållbar värld.
                    Förvaltarna arbetar aktivt med att välja in hållbara bolag
                    och att påverka bolag i en hållbar riktning.
                  </Typography>
                </AccordionItem>
              </Accordion>{" "}
              <Button
                variant="primary"
                size="medium"
                actionTheme="blue"
                text="Load more"
              />
            </Container>
          )}
        </Container>
        <Footer {...argsFooter} />
      </div>
    </>
  );
}

export default App;
